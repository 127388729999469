<template>
  <b-card title="Profile History">
    <!-- search input -->
    <div class="custom-search float-right">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Cari</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            name="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <div v-if="!rows || isLoadingTable" class="text-center">
        <b-spinner label="Tunggu..."></b-spinner>
    </div>
    <vue-good-table
      v-if="rows && !isLoadingTable"
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Row number -->
        <span
          v-if="props.column.field === 'row_number'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ (props.row.originalIndex + 1) }}</span>
        </span>

        <!-- Column: No. Tel -->
        <span
          v-if="props.column.field === 'phone'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Home Address -->
        <span
          v-if="props.column.field === 'home_address'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Company -->
        <span
          v-if="props.column.field === 'company'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Company Address -->
        <span
          v-if="props.column.field === 'company_address'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Company City -->
        <span
          v-if="props.column.field === 'company_city'"
          class="text-nowrap"
        >
        </span>


        <!-- Column: Office No. Tel -->
        <span
          v-if="props.column.field === 'company_phone'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Office Email -->
        <span
          v-if="props.column.field === 'company_email'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Position -->
        <span
          v-if="props.column.field === 'position'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Updated at -->
        <span
          v-if="props.column.field === 'created_at'"
          class="text-nowrap"
        >{{ props.formattedRow[props.column.field] }}
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Data 1 hingga
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> dari {{ props.total }} </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>

</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BForm, BButton, BSpinner
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BForm,
    BButton,
    BSpinner
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
            label: 'No',
            field: 'row_number'
        },
        {
          label: 'No. Tel',
          field: 'phone',
        },
        {
          label: 'Home Address',
          field: 'home_address',
        },
        {
          label: 'Company Name',
          field: 'company',
        },
        {
          label: 'Company Address',
          field: 'company_address',
        },
        {
          label: 'Company City',
          field: 'company_city',
        },
        {
          label: 'Office No. Tel',
          field: 'company_phone',
        },
        {
          label: 'Office Email',
          field: 'company_email',
        },
        {
          label: 'Job Title',
          field: 'position',
        },
        {
          label: 'Submitted At',
          field: 'created_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd-MM-yyyy HH:mm',
        }
      ],
      rows: [],
      searchTerm: '',

      isLoadingTable: false,
    }
  },
  computed: {
  },
  methods: {
    fetchData() {
      this.isLoadingTable = true

      this.rows = [
            {
                "id": 12,
                "user_id": 1,
                "phone": "08123455888",
                "company": "Company 124",
                "company_address": "Alamat companya",
                "company_city": "Jakartaaa",
                "company_phone": "021-2903491",
                "company_email": "test@gmail.coma",
                "position": "COO",
                "home_address": "Jl. Suka Maju No. 811",
                "created_at": "2022-10-30 15:09:43",
                "created_by": 1,
                "updated_at": "2022-10-30 15:09:43",
                "updated_by": null
            },
            {
                "id": 11,
                "user_id": 1,
                "phone": "08123455866",
                "company": "Company 123",
                "company_address": "Alamat company",
                "company_city": "Jakarta",
                "company_phone": "021-2903490",
                "company_email": "test@gmail.com",
                "position": "COO",
                "home_address": "Jl. Suka Maju No. 812",
                "created_at": "2022-10-30 15:07:27",
                "created_by": 1,
                "updated_at": "2022-10-30 15:07:27",
                "updated_by": null
            },
            {
                "id": 10,
                "user_id": 1,
                "phone": "08123455868",
                "company": "Company 124",
                "company_address": "Alamat companya",
                "company_city": "Jakartaa",
                "company_phone": "021-2903491",
                "company_email": "test@gmail.coma",
                "position": "COO",
                "home_address": "Jl. Suka Maju No. 8121",
                "created_at": "2022-10-30 15:06:10",
                "created_by": 1,
                "updated_at": "2022-10-30 15:06:10",
                "updated_by": null
            },
            {
                "id": 9,
                "user_id": 1,
                "phone": "08123455866",
                "company": "Company 124",
                "company_address": "Alamat companya",
                "company_city": "Jakartaa",
                "company_phone": "021-2903491",
                "company_email": "test@gmail.coma",
                "position": "COO",
                "home_address": "Jl. Suka Maju No. 8121",
                "created_at": "2022-10-30 15:05:52",
                "created_by": 1,
                "updated_at": "2022-10-30 15:05:52",
                "updated_by": null
            },
            {
                "id": 8,
                "user_id": 1,
                "phone": "08123455867",
                "company": "Company 123",
                "company_address": "Alamat company",
                "company_city": "Jakarta",
                "company_phone": "021-2903490",
                "company_email": "test@gmail.com",
                "position": "COO",
                "home_address": "Jl. Suka Maju No. 812",
                "created_at": "2022-10-30 14:56:47",
                "created_by": 1,
                "updated_at": "2022-10-30 14:56:47",
                "updated_by": null
            },
            {
                "id": 7,
                "user_id": 1,
                "phone": "08123455866",
                "company": "Company 123",
                "company_address": "Alamat company",
                "company_city": "Jakarta",
                "company_phone": "021-2903490",
                "company_email": "test@gmail.com",
                "position": "COO",
                "home_address": "Jl. Suka Maju No. 812",
                "created_at": "2022-10-30 14:51:49",
                "created_by": 1,
                "updated_at": "2022-10-30 14:51:49",
                "updated_by": null
            },
            {
                "id": 6,
                "user_id": 1,
                "phone": "08123455866",
                "company": "Company 123",
                "company_address": "Alamat company",
                "company_city": "Jakarta",
                "company_phone": "021-2903490",
                "company_email": "test@gmail.com",
                "position": "COO",
                "home_address": "Jl. Suka Maju No. 812",
                "created_at": "2022-10-30 14:51:16",
                "created_by": 1,
                "updated_at": "2022-10-30 14:51:16",
                "updated_by": null
            },
            {
                "id": 5,
                "user_id": 1,
                "phone": "08123455866",
                "company": "Company 123",
                "company_address": "Alamat company",
                "company_city": "Jakarta",
                "company_phone": "021-2903490",
                "company_email": "test@gmail.com",
                "position": "COO",
                "home_address": "Jl. Suka Maju No. 812",
                "created_at": "2022-10-30 14:50:32",
                "created_by": 1,
                "updated_at": "2022-10-30 14:50:32",
                "updated_by": null
            },
            {
                "id": 4,
                "user_id": 1,
                "phone": "08123455866",
                "company": "Company 123",
                "company_address": "Alamat company",
                "company_city": "Jakarta",
                "company_phone": "021-2903490",
                "company_email": "test@gmail.com",
                "position": "COO",
                "home_address": "Jl. Suka Maju No. 812",
                "created_at": "2022-10-18 13:13:52",
                "created_by": 1,
                "updated_at": "2022-10-18 13:13:52",
                "updated_by": null
            },
            {
                "id": 3,
                "user_id": 1,
                "phone": "08123455866",
                "company": "Company 123",
                "company_address": "Alamat company",
                "company_city": "Jakarta",
                "company_phone": "021-2903490",
                "company_email": "test@gmail.com",
                "position": "COO",
                "home_address": "Jl. Suka Maju No. 812",
                "created_at": "2022-10-18 13:13:36",
                "created_by": 1,
                "updated_at": "2022-10-18 13:13:36",
                "updated_by": null
            },
            {
                "id": 2,
                "user_id": 1,
                "phone": "08111881261",
                "company": "Nothing",
                "company_address": "Jl. Suka Maju No. 4",
                "company_city": "Jakarta",
                "company_phone": "021-2939985",
                "company_email": "company@email.com",
                "position": "CEO",
                "home_address": "Jl. Suka Maju No. 5",
                "created_at": "2022-10-18 00:00:00",
                "created_by": 1,
                "updated_at": null,
                "updated_by": null
            },
            {
                "id": 1,
                "user_id": 1,
                "phone": "08111881261",
                "company": "Nothing",
                "company_address": "Jl. Suka Maju No. 3",
                "company_city": "Jakarta",
                "company_phone": "021-2939984",
                "company_email": "company@email.com",
                "position": "CEO",
                "home_address": "Jl. Suka Maju No. 4",
                "created_at": "2022-10-17 00:00:00",
                "created_by": 1,
                "updated_at": null,
                "updated_by": null
            }
        ]

        this.isLoadingTable = false
    },
  },
  created() {
    this.fetchData()
  },
}
</script>